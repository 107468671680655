import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common-components/Icon';
import { FormattedMessage } from 'react-intl';
import { change } from 'redux-form';
import ErrorMessage from './ErrorMessage';

const propTypes = {
  input: PropTypes.shape().isRequired,
  accept: PropTypes.string,
  meta: PropTypes.shape().isRequired,
  maxfile: PropTypes.string,
  multiple: PropTypes.bool,
  allowDelete: PropTypes.bool,
};

const defaultProps = {
  accept: null,
  maxfile: null,
  multiple: false,
  allowDelete: true,
};

class RenderDSFileField extends React.Component {
  constructor(props) {
    super(props);

    this.handleDestroy = this.handleDestroy.bind(this);
    this.formatBytes = this.formatBytes.bind(this);
    this.checkSize = this.checkSize.bind(this);
    this.checkType = this.checkType.bind(this);
    this.createRichFiles = this.createRichFiles.bind(this);
    this.setPreviousFiles = this.setPreviousFiles.bind(this);
    this.state = {
      files: Array.isArray(props.input.value) ? props.input.value : [props.input.value],
    };
  }

  componentDidUpdate(prevProps) {
    const { input: { value } } = this.props;
    if (prevProps.input.value !== value) {
      this.setPreviousFiles(value);
    }
  }

  setPreviousFiles(file) {
    this.setState({ files: Array.isArray(file) ? file : [file] });
  }

  handleDestroy(file) {
    const { input: { onChange, name }, meta: { dispatch, form } } = this.props;
    const files = Array.from(this.state.files);
    const i = files.indexOf(file);
    if (i >= 0) {
      file.id ? delete files[i].file : files.splice(i, 1);

      onChange(files);
      if (file.id) dispatch(change(form, `${name}[${i}]._destroy`, true));
    }
  }

  formatBytes(bytes) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let size = bytes;
    let formatted;

    for (let i = 0; i < units.length; i += 1) {
      if (size < 1000) {
        formatted = `<strong>${size.toFixed(1)}</strong> ${units[i]}`;
        break;
      } else {
        size /= 1000;
      }
    }

    return formatted;
  }

  checkSize(file) {
    const { maxfile } = this.props;
    if (!maxfile) return;
    if (file.size > +maxfile * 1000 * 1000) {
      // eslint-disable-next-line no-param-reassign
      file.error = <FormattedMessage id="shared.common_components.file_too_big" values={{ size: Math.round(file.size / 1000 / 1000), max: +maxfile }} />;
    }
  }

  checkType(file) {
    const { accept } = this.props;

    if (!accept) return;

    const acceptedFiles = accept.split(',');
    const mimeType = file.type;
    const baseMimeType = mimeType.replace(/\/.*$/, '');

    for (let validType of acceptedFiles) {
      validType = validType.trim();

      if (validType.charAt(0) === '.') {
        if (file.name.toLowerCase().includes(
          validType.toLowerCase(),
          file.name.length - validType.length,
        )) return;
      } else if (/\/\*$/.test(validType)) {
        if (baseMimeType === validType.replace(/\/.*$/, '')) return;
      } else if (mimeType === validType) return;
    }

    // eslint-disable-next-line no-param-reassign
    file.error = <FormattedMessage id="shared.common_components.invalid_file_type" />;
  }

  createRichFiles(e) {
    const { input: { onChange }, multiple } = this.props;
    const { files: oldFile } = this.state;
    const { files } = e.target;
    const newFiles = Object.values(files).map((file) => {
      this.checkSize(file);
      this.checkType(file);
      return file;
    });
    const jointFiles = (oldFile.length) ? oldFile.concat(newFiles) : newFiles;
    multiple && jointFiles.length > 0 ?
      onChange(jointFiles) :
      // eslint-disable-next-line no-nested-ternary
      onChange(jointFiles.length ? (jointFiles.length > 1 ? jointFiles[1] : jointFiles[0]) : []);
  }

  render() {
    const {
      input: { name, value }, accept, multiple, meta, allowDelete, ...rest
    } = this.props;
    const { files } = this.state;

    return (
      <React.Fragment>
        <input type="file" multiple={multiple} name={name} {...rest} onChange={this.createRichFiles} title={value.name} />
        <div className="filepicker-content">
          { files.length
            ?
            files.map((file, index) =>
              (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={index}>
                  {(file.name || file.file) &&
                    <div className="file-preview">
                      <div className="file-details">
                        {file.error
                          ?
                            <div className="file-error-message">{file.error}</div>
                          :
                            <div className="file-size-cnt">
                              { (file.size && (file.size > 0 || file.file.size > 0)) &&
                                <div
                                  className="file-size"
                                  // eslint-disable-next-line react/no-danger
                                  dangerouslySetInnerHTML={{
                                    __html: this.formatBytes(file.size ?
                                      file.size : file.file.size),
                                  }}
                                />
                              }
                              <div className="file-success-mark">
                                <Icon name="huge-tick-01" />
                              </div>
                            </div>
                        }
                        <div className="file-name">{file.name ? file.name : file.file.name}</div>
                        {
                          allowDelete &&
                            <div className="action-icons">
                              <button type="button" onClick={() => this.handleDestroy(file)}>
                                <Icon name="huge-delete-02" />
                              </button>
                            </div>
                        }
                      </div>
                    </div>
                  }
                </React.Fragment>
              ))
            :
            <div className="file-default-message">
              <Icon name="huge-upload-04" size="medium" />
              <FormattedMessage id="shared.common_components.attach_default" />
            </div>
          }
        </div>
        <ErrorMessage {...meta} />
      </React.Fragment>
    );
  }
}

RenderDSFileField.propTypes = propTypes;
RenderDSFileField.defaultProps = defaultProps;

export default RenderDSFileField;
