import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { injectIntl } from 'react-intl';

import RenderDSFileField from 'common-components/form/RenderDSFileField';
import { required, isAsset } from 'utils/form/validations';
import { cleanFileName } from 'utils/form/normalizations';

const propTypes = {
  fieldLabel: PropTypes.func,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
  assetField: PropTypes.shape().isRequired,
};

const defaultProps = {
  fieldLabel: undefined,
};

function CvInfo(props) {
  const profMetadata = 'user_representations_attributes[0].professional_metadata_attributes.';
  const { intl: { messages }, fieldLabel, assetField } = props;
  const validations = (assetField.required) ? [required, isAsset] : [isAsset];

  return (
    <div className="form-group">
      <label className="label control-label">
        {fieldLabel(assetField, messages['jobs.attach_cv'])}
      </label>
      <div className="filepicker" data-input-name={`user.${profMetadata}assets_attributes[0].file`} data-label-text={messages['jobs.attach_cv']}>
        <Field
          className="block-input"
          component={RenderDSFileField}
          name={`${profMetadata}assets_attributes[0].file`}
          validate={validations}
          normalize={cleanFileName}
          accept="
            application/pdf, text/plain, application/msword, application/doc,
            application/odt, application/vnd.oasis.opendocument.text,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/docx
          "
        />
      </div>
      <span className="hint">
        {messages['jobs.cv_formats']}
      </span>
    </div>
  );
}

CvInfo.propTypes = propTypes;
CvInfo.defaultProps = defaultProps;

export default injectIntl(CvInfo);
