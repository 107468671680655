import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common-components/Icon';
import { injectIntl } from 'react-intl';
import { getUrlParams } from 'utils/helpers';
import SimpleFormat from 'common-components/SimpleFormat';
import Appsignal from '@appsignal/javascript';
import { ErrorBoundary } from '@appsignal/react';
import view from 'job-centers/containers/View';
import HeadhunterJobInfo from './HeadhunterJobInfo';
import RegistrationForm from './RegistrationForm';
import RegistrationUserLoggedForm from './RegistrationUserLoggedForm';
import Sidebar from './Sidebar';

const propTypes = {
  appsignal_key: PropTypes.string,
  anonymousJob: PropTypes.bool,
  companyColor: PropTypes.string.isRequired,
  companyOriginUrl: PropTypes.string,
  companyQuestions: PropTypes.arrayOf(PropTypes.shape({})),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  candidateAttributes: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    desiredSalary: PropTypes.string,
    phone: PropTypes.string,
    professionalTitle: PropTypes.string,
    userLocationAttributes: PropTypes.shape({
      uid: PropTypes.string,
      route: PropTypes.string,
    }),
  }),
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
    formatDate: PropTypes.func,
  }).isRequired,
  formData: PropTypes.shape({
    company: PropTypes.shape(),
  }).isRequired,
  inscribedInOffer: PropTypes.bool.isRequired,
  job: PropTypes.shape({
    activation_date: PropTypes.string,
    category: PropTypes.string,
    company_id: PropTypes.number,
    department: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    header_image: PropTypes.shape({
      image_url: PropTypes.string,
    }),
    site: PropTypes.shape({
      header_image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    job_setting: PropTypes.shape({
      hide_min_requirements: PropTypes.bool,
      hide_publication_date: PropTypes.bool,
      hide_location: PropTypes.bool,
    }),
    job_locations: PropTypes.arrayOf(PropTypes.shape({
      region: PropTypes.shape({
        name: PropTypes.string,
      }),
    })),
    order_date: PropTypes.string,
    min_requirements: PropTypes.string,
    professional_level: PropTypes.string,
    salary_description: PropTypes.string,
    sector: PropTypes.string,
    skill_tag_list: PropTypes.arrayOf(PropTypes.string),
    subcategory: PropTypes.string,
    title: PropTypes.string,
    workday: PropTypes.string,
    work_mode: PropTypes.string,
    iframe_src: PropTypes.string,
  }).isRequired,
  jobClosed: PropTypes.bool.isRequired,
  headerImagePreview: PropTypes.string,
  languageList: PropTypes.arrayOf(PropTypes.string).isRequired,
  languageLevels: PropTypes.objectOf(PropTypes.number).isRequired,
  genders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  legalInfoText: PropTypes.string,
  legalMessage: PropTypes.string,
  questionTypes: PropTypes.shape({
    checkbox: PropTypes.number,
    radio: PropTypes.number,
    select: PropTypes.number,
    text: PropTypes.number,
  }),
  userLoggedIn: PropTypes.bool.isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
  idDocumentFieldsForm: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  customFieldsForm: PropTypes.arrayOf(PropTypes.shape({})),
  siteUrl: PropTypes.string,
  whatsappMessageLink: PropTypes.string.isRequired,
};

const defaultProps = {
  appsignal_key: '',
  anonymousJob: false,
  companyQuestions: [],
  countries: [],
  companyOriginUrl: undefined,
  headerImagePreview: null,
  legalInfoText: null,
  candidateAttributes: undefined,
  legalMessage: null,
  questionTypes: {
    checkbox: 2,
    radio: 3,
    select: 1,
    text: 4,
  },
  systemFieldsForm: [],
  idDocumentFieldsForm: {},
  customFieldsForm: [],
  siteUrl: undefined,
};

class ShowContent extends React.Component {
  constructor(props) {
    super(props);

    this.openForm = this.openForm.bind(this);
    this.handleShowForm = this.handleShowForm.bind(this);
    this.skillTagList = this.skillTagList.bind(this);
    this.state = {
      openedForm: !props.jobClosed && !props.inscribedInOffer && getUrlParams().displayed_form === 'true',
    };
  }

  handleShowForm(openedForm) {
    const jobForm = document.querySelector('.form-wrapper');
    const jobDetail = document.querySelector('.job-info');
    if (openedForm) {
      jobForm.classList.remove('is-hidden');
      jobDetail.classList.add('is-hidden');
    } else {
      jobForm.classList.add('is-hidden');
      jobDetail.classList.remove('is-hidden');
    }

    this.scrollTo();
  }

  scrollTo() {
    document.querySelector('#info-and-form-cnt').scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }

  skillTagList() {
    const { job: { skill_tag_list: skillTagList } } = this.props;
    return skillTagList.map((skill) => <li key={skill}>{skill}</li>);
  }

  newLineFormat(text) {
    return !text.match(/<p>/g) && !text.match(/<ul>/g);
  }

  openForm() {
    this.setState(({ openedForm }) => ({
      openedForm: !openedForm,
    }), () => {
      this.handleShowForm(this.state.openedForm);
    });
    setTimeout(() => {
      if (this.state.openedForm) {
        document.getElementById('footer-career-site').classList.add('color-bg');
      } else {
        document.getElementById('footer-career-site').classList.remove('color-bg');
      }
    }, 0);
  }

  render() {
    const {
      appsignal_key: appsignalKey,
      anonymousJob,
      companyColor,
      companyOriginUrl, legalInfoText,
      job, countries, legalMessage,
      job: { job_setting: jobSetting },
      customFieldsForm,
      formData, companyQuestions, languageList,
      jobClosed, questionTypes, languageLevels,
      inscribedInOffer, systemFieldsForm,
      userLoggedIn, idDocumentFieldsForm,
      candidateAttributes,
      intl: { messages, formatDate },
      siteUrl,
      whatsappMessageLink,
    } = this.props;
    const { openedForm } = this.state;

    const appsignal = new Appsignal({
      key: appsignalKey,
    });

    const hasForm = !jobClosed && !inscribedInOffer;

    const candidateAttrs = candidateAttributes === null ? undefined : candidateAttributes;

    return (
      <ErrorBoundary
        instance={appsignal}
      >
        <div className={`job-show-cnt ${openedForm ? '' : 'white-bg'}`}>
          <HeadhunterJobInfo
            job={this.props.job}
            headerImagePreview={this.props.headerImagePreview}
            openedForm={openedForm}
            anonymousJob={anonymousJob}
          />
          <style dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
            __html: [
              '.job-show-cnt .wrapper .job-info ul > li:before {',
              `  background: ${companyColor};`,
              '}',
            ].join('\n'),
          }}
          />
          <div className="wrapper pb-[64px]">
            {
              (!anonymousJob && !openedForm) &&
                <a href={siteUrl} className="back-link">
                  <Icon name="huge-arrow-left-02" />
                  {messages['jobs.home']}
                </a>
            }
            { openedForm &&
              <button type="button" className="back-link" onClick={() => this.openForm(false)}>
                <Icon name="huge-arrow-left-02" />
                {messages['jobs.link_to_job']}
              </button>
            }
            <h1 className="offer-title mt-2">{job.title}</h1>
            { openedForm &&
              <div className="flex mt-2">
                <Icon name="huge-clock-01" klass="mr-2 is-grey" />
                <div className="h4 !font-normal">
                  { !jobSetting.hide_publication_date &&
                    <span className="is-grey date">
                      {`${messages['jobs.date']} ${formatDate(new Date(job.activation_date || job.order_date), { year: 'numeric', month: 'long', day: 'numeric' })}`}
                    </span>
                  }
                  {!jobSetting.hide_location && job.job_locations.length &&
                    job.job_locations[0].region !== undefined &&
                    <h2 className="location">
                      <span className="is-grey">
                        {`${job.job_locations[0].region.name}`}
                      </span>
                    </h2>
                  }
                </div>
              </div>
            }
          </div>
          <div className="wrapper flex" id="info-and-form-cnt">
            <Sidebar
              job={job}
              companyColor={companyColor}
              anonymousJob={anonymousJob}
              showForm={this.handleShowForm}
              inscribedInOffer={inscribedInOffer}
              jobClosed={jobClosed}
              companyOriginUrl={companyOriginUrl}
              userLoggedIn={userLoggedIn}
              openedForm={openedForm}
              openForm={this.openForm}
              whatsappMessageLink={whatsappMessageLink}
            />
            <div className={`job-info${openedForm ? ' is-hidden' : ''}`}>
              <div className="info">
                <div className="description">
                  { job.description &&
                    <div className="desc-cnt">
                      <h3>{messages['jobs.description']}</h3>
                      { job.iframe_src &&
                        <div className="video-iframe mb-6">
                          <iframe title="offer-video" src={job.iframe_src} width="640" height="360" frameBorder="0" allowFullScreen />
                        </div>
                      }
                      <SimpleFormat text={job.description} wrapperTag="div" tooltip="no" newLineFormat={this.newLineFormat(job.description)} />

                    </div>
                  }
                  { !job.job_setting.hide_min_requirements && job.min_requirements && (
                    <div>
                      <h3>{messages['jobs.min_requirements']}</h3>
                      <SimpleFormat text={job.min_requirements} wrapperTag="div" tooltip="no" newLineFormat={this.newLineFormat(job.min_requirements)} />

                    </div>
                  )}
                  { this.skillTagList().length > 0 && (
                    <div>
                      <h3>{messages['jobs.skills']}</h3>
                      <ul>
                        {this.skillTagList()}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {
              hasForm &&
              (
                userLoggedIn && !anonymousJob
                  ?
                    <RegistrationUserLoggedForm
                      formData={this.props.formData}
                      companyQuestions={this.props.companyQuestions}
                      questionTypes={this.props.questionTypes}
                      companyColor={this.props.companyColor}
                      systemFieldsForm={this.props.systemFieldsForm}
                      idDocumentFieldsForm={this.props.idDocumentFieldsForm}
                      customFieldsForm={this.props.customFieldsForm}
                      countries={this.props.countries}
                      legalMessage={this.props.legalMessage}
                      candidate={candidateAttrs}
                      legalInfoText={this.props.legalInfoText}
                      job={this.props.job}
                      languageList={this.props.languageList}
                      languageLevels={this.props.languageLevels}
                      userLoggedIn={userLoggedIn}
                      openedForm={openedForm}
                      genders={this.props.genders}
                    />
                  :
                    <RegistrationForm
                      formData={formData}
                      companyQuestions={companyQuestions}
                      questionTypes={questionTypes}
                      companyColor={companyColor}
                      systemFieldsForm={systemFieldsForm}
                      idDocumentFieldsForm={idDocumentFieldsForm}
                      customFieldsForm={customFieldsForm}
                      countries={countries}
                      legalMessage={legalMessage}
                      candidate={candidateAttrs}
                      legalInfoText={legalInfoText}
                      job={job}
                      languageList={languageList}
                      languageLevels={languageLevels}
                      openedForm={openedForm}
                      genders={this.props.genders}
                      anonymousJob={this.props.anonymousJob}
                    />
              )
            }
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

ShowContent.propTypes = propTypes;
ShowContent.defaultProps = defaultProps;

const wrappedComponent = injectIntl(ShowContent);
export default view(wrappedComponent);
