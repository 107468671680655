import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Appsignal from '@appsignal/javascript';
import { ErrorBoundary } from '@appsignal/react';
import Select from 'react-select';
import { customStyles } from 'utils/helpers';

import { post } from 'utils/api/fetch-client';
import view from 'job-centers/containers/View';
import OfferList from './OfferList';
import VideoIframe from './VideoIframe';

const propTypes = {
  appsignal_key: PropTypes.string,
  intl: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
  referrer_source: PropTypes.string,
  filter_params: PropTypes.shape({
    site_id: PropTypes.string,
    city_id: PropTypes.string,
    county_id: PropTypes.string,
    country_id: PropTypes.string,
    aggregation_id: PropTypes.string,
    subcategory: PropTypes.string,
    filter_city: PropTypes.string,
    filter_county: PropTypes.string,
    filter_country: PropTypes.string,
    filter_subcategory: PropTypes.string,
    filter_aggregation: PropTypes.string,
    filter_site: PropTypes.string,
  }),
};

const defaultProps = {
  appsignal_key: '',
  referrer_source: null,
  filter_params: {},
};

class IndexContent extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeCounty = this.handleChangeCounty.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeSite = this.handleChangeSite.bind(this);
    this.handleChangeSubcategory = this.handleChangeSubcategory.bind(this);
    this.handleChangeAggregation = this.handleChangeAggregation.bind(this);

    const {
      filter_params: {
        site_id: siteId,
        city_id: cityId,
        county_id: countyId,
        country_id: countryId,
        aggregation_id: aggregationId,
        subcategory,
      },
    } = props;

    this.state = {
      jobs: null,
      counties: [],
      countries: [],
      cities: [],
      subcategories: [],
      aggregations: [],
      sites: [],
      countyId,
      countryId,
      cityId,
      siteId,
      aggregationId,
      subcategory,
      headerImageUrl: null,
      corporateImageUrl: null,
      headerImageMobileUrl: null,
      settings: {},
      pagingData: { page: 1, total: 1 },
      changeSiteFilter: false,
      changeCountryFilter: false,
      changeCountyFilter: false,
      changeCityFilter: false,
      changeAggregationFilter: false,
      changeSubcategoryFilter: false,
    };
  }

  componentDidMount() {
    this.handleSubmit(null);
  }

  handleSubmit(page) {
    const {
      countyId,
      countryId,
      cityId,
      siteId,
      subcategory,
      aggregationId,
    } = this.state;

    const { intl } = this.props;
    const { locale } = intl;

    const url = Routes.search_custom_companies_headhunter_jobs_path();

    post(
      url,
      {
        page,
        locale,
        county_id: countyId,
        country_id: countryId,
        city_id: cityId,
        site_ids: siteId,
        subcategory,
        aggregation_id: aggregationId,
      },
    ).then(data =>
      this.setState({
        jobs: data.jobs,
        totalJobs: data.total_jobs,
        totalVacancies: data.total_vacancies,
        counties: data.counties,
        countries: data.countries,
        cities: data.cities,
        subcategories: data.subcategories,
        aggregations: data.aggregations,
        sites: data.sites,
        pagingData: data.paging_data,
        headerImageUrl: data.header_image_url,
        corporateImageUrl: data.corporate_image_url,
        headerImageMobileUrl: data.header_image_mobile_url,
        settings: data.settings,
        corporateTitle: data.corporate_title,
        jobCenterDescription: data.job_center_description,
        jobListText: data.job_list_text,
      }));
  }

  handleChangeCounty(val) {
    this.setState({
      countyId: val?.value,
      changeCountyFilter: true,
    }, () => this.handleSubmit(1));
  }

  handleChangeCountry(val) {
    this.setState({
      countryId: val?.value,
      changeCountryFilter: true,
    }, () => this.handleSubmit(1));
  }

  handleChangeCity(val) {
    this.setState({
      cityId: val?.value,
      changeCityFilter: true,
    }, () => this.handleSubmit(1));
  }

  handleChangeSite(val) {
    this.setState({
      siteId: val?.value,
      changeSiteFilter: true,
    }, () => this.handleSubmit(1));
  }

  handleChangeSubcategory(val) {
    this.setState({
      subcategory: val?.value,
      changeSubcategoryFilter: true,
    }, () => this.handleSubmit(1));
  }

  handleChangeAggregation(val) {
    this.setState({
      aggregationId: val?.value,
      changeAggregationFilter: true,
    }, () => this.handleSubmit(1));
  }

  renderDescription(jobCenterDescription, corporateTitle, color = '') {
    return (
      <div className="desc-cnt">
        <div className="title mb-10" style={{ color }}>
          {corporateTitle || <FormattedMessage id="jobs.jobcenter_description" />}
        </div>
        <div className="description">
          <div
            dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
              __html: jobCenterDescription,
            }}
          />
        </div>
      </div>
    );
  }

  renderVideo(videoUrl) {
    if (videoUrl) {
      return (
        <div className="video-inner">
          <VideoIframe videoUrl={videoUrl} />
        </div>
      );
    }

    return undefined;
  }

  renderHeaderOptions(displayOptions, corporateTitle, jobCenterDescription, videoUrl, corporateImageUrl, color) {
    switch (displayOptions) {
      case 'description_video':
        return (
          <div className="job-index-description mb-10">
            {this.renderDescription(jobCenterDescription, corporateTitle, color)}
            { videoUrl && (
            <div className="video">
              {this.renderVideo(videoUrl)}
            </div>
            )}
          </div>
        );
      case 'description_image':
        return (
          <div className="job-index-description mb-10">
            {this.renderDescription(jobCenterDescription, corporateTitle, color)}
            { corporateImageUrl && (
            <div className="image">
              <img src={corporateImageUrl} />
            </div>
            )}
          </div>
        );
      case 'description':
        return (
          <div className="job-index-description mb-10 centered">
            {this.renderDescription(jobCenterDescription, corporateTitle, color)}
          </div>
        );
      case 'video':
        return (
          <div className="job-index-description mb-10 centered">
            {this.renderVideo(videoUrl)}
          </div>
        );

      default:
        return undefined;
    }
  }

  renderFilters() {
    const {
      sites, siteId,
      counties, subcategories, countyId, subcategory,
      countries, aggregationId, aggregations,
      countryId, cities, cityId,
      settings: {
        province_filter: provinceFilter,
        site_filter: siteFilter,
        category_filter: categoryFilter,
        country_filter: countryFilter,
        city_filter: cityFilter,
        aggregation_filter: aggregationFilter,
      },
      changeSiteFilter, changeCountryFilter, changeCountyFilter, changeCityFilter,
      changeAggregationFilter, changeSubcategoryFilter,
    } = this.state;

    const {
      filter_params: {
        site_id: filterSiteId,
        city_id: filterCityId,
        county_id: filterCountyId,
        country_id: filterCountryId,
        aggregation_id: filterAggregationId,
        subcategory: filterSubcategoryId,
        filter_city: filterCity,
        filter_county: filterCounty,
        filter_country: filterCountry,
        filter_subcategory: filterSubcategory,
        filter_aggregation: filterAggregation,
        filter_site: filterSite,
      },
    } = this.props;

    const showSiteFilter = filterSite !== 'hidden' && siteFilter;
    const showCityFilter = filterCity !== 'hidden' && cityFilter;
    const showCountryFilter = filterCountry !== 'hidden' && countryFilter;
    const showCountyFilter = filterCounty !== 'hidden' && provinceFilter;
    const showCategoryFilter = filterSubcategory !== 'hidden' && categoryFilter;
    const showAggregationFilter = filterAggregation !== 'hidden' && aggregationFilter;

    const filterSiteIdValue = changeSiteFilter ? siteId : siteId || filterSiteId;
    const filterCountryIdValue = changeCountryFilter ? countryId : countryId || filterCountryId;
    const filterCountyIdValue = changeCountyFilter ? countyId : countyId || filterCountyId;
    const filterCityIdValue = changeCityFilter ? cityId : cityId || filterCityId;
    const filterAggregationIdValue = changeAggregationFilter ? aggregationId : aggregationId || filterAggregationId;
    const filterSubcategoryIdValue = changeSubcategoryFilter ? subcategory : subcategory || filterSubcategoryId;

    return (
      <div className="job-index-filters mb-10">
        <div className="wrapper">
          <div className="filters">
            {
              showSiteFilter && sites && sites.length > 1 && (
                <div className="job-filters">
                  <Select
                    simpleValue
                    autosize={false}
                    name="site_ids"
                    value={sites.filter(option => option.value === parseInt(filterSiteIdValue, 10))}
                    options={sites}
                    isClearable
                    onChange={this.handleChangeSite}
                    autoBlur
                    placeholder={<FormattedMessage id="jobs.site" />}
                    styles={customStyles}
                  />
                </div>
              )
            }
            {
              showCountryFilter && (
                <div className="job-filters">
                  <Select
                    simpleValue
                    autosize={false}
                    name="country_id"
                    value={countries.filter(option => option.value === filterCountryIdValue)}
                    options={countries}
                    isClearable
                    onChange={this.handleChangeCountry}
                    autoBlur
                    placeholder={<FormattedMessage id="jobs.country" />}
                    styles={customStyles}
                  />
                </div>
              )
            }
            {
              showCountyFilter && (
                <div className="job-filters">
                  <Select
                    simpleValue
                    autosize={false}
                    name="county_id"
                    value={counties.filter(option => option.value === filterCountyIdValue)}
                    options={counties}
                    isClearable
                    onChange={this.handleChangeCounty}
                    autoBlur
                    placeholder={<FormattedMessage id="jobs.province" />}
                    styles={customStyles}
                  />
                </div>
              )
            }
            {
              showCityFilter && (
                <div className="job-filters">
                  <Select
                    simpleValue
                    autosize={false}
                    name="city_id"
                    value={cities.filter(option => option.value === filterCityIdValue)}
                    options={cities}
                    isClearable
                    onChange={this.handleChangeCity}
                    autoBlur
                    placeholder={<FormattedMessage id="jobs.city" />}
                    styles={customStyles}
                  />
                </div>
              )
            }
            {
              showCategoryFilter && (
                <div className="job-filters">
                  <Select
                    simpleValue
                    autosize={false}
                    name="subcategory"
                    value={subcategories?.filter(option => option.options.find(valueOption => valueOption.value === filterSubcategoryIdValue))}
                    options={subcategories}
                    isClearable
                    onChange={this.handleChangeSubcategory}
                    autoBlur
                    placeholder={<FormattedMessage id="jobs.category" />}
                    styles={customStyles}
                  />
                </div>
              )
            }
            {
              showAggregationFilter && (
                <div className="job-filters">
                  <Select
                    simpleValue
                    autosize={false}
                    name="aggregation_id"
                    value={aggregations.filter(option => option.value === parseInt(filterAggregationIdValue, 10))}
                    options={aggregations}
                    isClearable
                    onChange={this.handleChangeAggregation}
                    autoBlur
                    placeholder={<FormattedMessage id="jobs.aggregation" />}
                    styles={customStyles}
                  />
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      appsignal_key: appsignalKey,
      referrer_source: referrerSource,
    } = this.props;
    const {
      jobs,
      totalJobs, totalVacancies, jobListText,
      settings: {
        video_url: videoUrl,
        color,
        display_options: displayOptions,
        external_job_center_url: jobCenterUrl,
        show_total_jobs: showTotalJobs,
        show_total_vacancies: showTotalVacancies,
        show_aggregation: showAggregation,
        show_title: showTitle,
      },
      settings,
      headerImageUrl,
      corporateImageUrl,
      headerImageMobileUrl,
      corporateTitle,
      jobCenterDescription,
      pagingData,
    } = this.state;
    const appsignal = new Appsignal({
      key: appsignalKey,
    });

    return (
      <ErrorBoundary
        instance={appsignal}
      >
        <div className="jobs">
          <div className="wrapper">
            {
              (headerImageUrl || headerImageMobileUrl) &&
                <div className={`job-index-header mb-20 ${headerImageUrl ? '' : 'only-mobile'}`}>
                  <picture>
                    <source srcSet={headerImageMobileUrl} media="(max-width: 600px)" />
                    <img src={headerImageUrl} />
                  </picture>
                  {jobCenterUrl &&
                    <a href={jobCenterUrl}>
                      {jobCenterUrl}
                    </a>
                  }
                </div>
            }
            {
              this.renderHeaderOptions(
                displayOptions,
                corporateTitle,
                jobCenterDescription,
                videoUrl,
                corporateImageUrl,
                color,
              )
            }
          </div>
          {
            jobs && jobs !== null && jobs.length > 0 &&
              <OfferList
                handleSubmit={this.handleSubmit}
                settings={settings}
                totalJobs={totalJobs}
                totalVacancies={totalVacancies}
                jobListText={jobListText}
                jobs={jobs}
                filters={this.renderFilters()}
                referrerSource={referrerSource}
                pagingData={pagingData}
                showTotalJobs={showTotalJobs}
                showTotalVacancies={showTotalVacancies}
                showAggregation={showAggregation}
                showTitle={showTitle}
              />
          }
          {
            jobs && jobs !== null && jobs.length === 0 &&
              <div className="wrapper">
                <div className="inner-card">
                  <div className="icon-job mx-auto mb-4 mt-2" />
                  <p className="h1 text-center !mb-4">
                    <FormattedMessage id="jobs.empty_job_title" />
                  </p>
                  <p className="is-grey !mb-2 text-center">
                    <FormattedMessage
                      id="jobs.empty_job_desc"
                      values={{
                        br: () => <br />,
                      }}
                    />
                  </p>
                </div>
              </div>
          }
        </div>
      </ErrorBoundary>
    );
  }
}

IndexContent.propTypes = propTypes;
IndexContent.defaultProps = defaultProps;

export default view(injectIntl(IndexContent));
