import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common-components/Icon';
import { injectIntl } from 'react-intl';


const propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPaginationClick: PropTypes.func.isRequired,
  onlyArrows: PropTypes.bool,
};

const defaultProps = {
  onlyArrows: false,
};

class Pagination extends React.Component {
  constructor(props) {
    super(props);

    this.previousPage = this.previousPage.bind(this);
    this.currentWindow = this.currentWindow.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  previousPage() {
    const {
      currentPage, onPaginationClick,
      intl: { formatMessage }, onlyArrows,
    } = this.props;
    const previous = !onlyArrows ? formatMessage({ id: 'shared.common_components.prev' }) : <Icon name="huge-arrow-left-01" size="small" />;
    const disabled = currentPage < 2;
    const buttonClass = !onlyArrows ? '' : 'btn';

    if (disabled) {
      return (
        <li>
          <button disabled type='button' className={`prev disabled no-mobile ${buttonClass}`}>
            {previous}
          </button>
        </li>
      );
    }

    return (
      <li>
        <button
          href="#"
          className={`prev no-mobile ${buttonClass}`}
          onClick={() => onPaginationClick(currentPage - 1)}
        >
          {previous}
        </button>
      </li>
    );
  }

  currentWindow() {
    const {
      currentPage, totalPages, onPaginationClick,
    } = this.props;
    const currentWindow = [];

    if (totalPages <= 5 || (currentPage >= 3 && currentPage <= 4 && totalPages === 6)) {
      for (let i = 1; i <= totalPages; i += 1) {
        if (currentPage === i) {
          currentWindow.push(<li key={`page-${i}`}><span className="active">{i}</span></li>);
        } else {
          currentWindow.push(<li key={`page-${i}`}><button onClick={() => onPaginationClick(i)}>{i}</button></li>);
        }
      }
      return currentWindow;
    }

    if (currentPage < 5) {
      let loopPages = currentPage + 2;
      if (totalPages - currentPage === 4) loopPages = currentPage + 1;
      if (totalPages - currentPage < 4) loopPages = currentPage + (totalPages - currentPage);
      for (let i = 1; i <= loopPages; i += 1) {
        if (currentPage === i) {
          currentWindow.push(<li key={`page-${i}`}><span className="active">{i}</span></li>);
        } else {
          currentWindow.push(<li key={`page-${i}`}><button onClick={() => onPaginationClick(i)}>{i}</button></li>);
        }
      }
      if (currentPage + 3 >= totalPages) return currentWindow;
      currentWindow.push(<li key="page-dots" className="dots no-mobile"><span className="ellipsis">...</span></li>);
      currentWindow.push(<li key={`page-${totalPages}`}><button onClick={() => onPaginationClick(totalPages)}>{totalPages}</button></li>);
      return currentWindow;
    }

    if (currentPage >= 5 && totalPages - 3 > currentPage) {
      currentWindow.push(<li key={`page-${1}`}><button onClick={() => onPaginationClick(1)}>{1}</button></li>);
      currentWindow.push(<li key="page-dots-1" className="dots no-mobile"><span className="ellipsis">...</span></li>);
      const index = currentPage === 5 ? currentPage - 1 : currentPage - 2;
      const loopPages = totalPages - currentPage === 4 ? currentPage + 1 : currentPage + 2;
      for (let i = index; i <= loopPages; i += 1) {
        if (currentPage === i) {
          currentWindow.push(<li key={`page-${i}`}><span className="active">{i}</span></li>);
        } else {
          currentWindow.push(<li key={`page-${i}`}><button onClick={() => onPaginationClick(i)}>{i}</button></li>);
        }
      }
      currentWindow.push(<li key="page-dots-2" className="dots no-mobile"><span className="ellipsis">...</span></li>);
      currentWindow.push(<li key={`page-${totalPages}`}><button onClick={() => onPaginationClick(totalPages)}>{totalPages}</button></li>);
      return currentWindow;
    }

    currentWindow.push(<li key={`page-${1}`}><button onClick={() => onPaginationClick(1)}>{1}</button></li>);
    currentWindow.push(<li key="page-dots-1" className="dots no-mobile"><span className="ellipsis">...</span></li>);
    for (let i = currentPage === 5 ? currentPage - 1 : currentPage - 2; i <= totalPages; i += 1) {
      if (currentPage === i) {
        currentWindow.push(<li key={`page-${i}`}><span className="active">{i}</span></li>);
      } else {
        currentWindow.push(<li key={`page-${i}`}><button onClick={() => onPaginationClick(i)}>{i}</button></li>);
      }
    }
    return currentWindow;
  }

  nextPage() {
    const {
      currentPage, totalPages, onPaginationClick,
      intl: { formatMessage }, onlyArrows,
    } = this.props;
    const next = !onlyArrows ? formatMessage({ id: 'shared.common_components.next' }) : <Icon name="huge-arrow-right-01" size="small" />;
    const disabled = currentPage === totalPages;
    const buttonClass = !onlyArrows ? '' : 'btn';

    if (disabled) {
      return (
        <li>
          <button disabled type="button" className={`next disabled no-mobile ${buttonClass}`}>
            {next}
          </button>
        </li>
      );
    }

    return (
      <li>
        <button className={`next no-mobile ${buttonClass}`} onClick={() => onPaginationClick(currentPage + 1)}>
          {next}
        </button>
      </li>
    );
  }

  render() {
    const { totalPages, onlyArrows } = this.props;

    if (totalPages < 2) return null;

    return (
      <ul className={!onlyArrows ? 'pagination' : 'pagination-resume pagination-buttons ml-4'}>
        {this.previousPage()}
        { !onlyArrows &&
          this.currentWindow()
        }
        {this.nextPage()}
      </ul>
    );
  }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default injectIntl(Pagination);
