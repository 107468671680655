import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findAll } from 'highlight-words-core';
import parser from 'html-react-parser';

import { splitParams } from 'utils/helpers';

const propTypes = {
  filterParams: PropTypes.string,
  headhunterJobsFilters: PropTypes.shape(),
  text: PropTypes.string,
  headhunterJobs: PropTypes.bool,
  mainSearch: PropTypes.bool,
};

const defaultProps = {
  mainSearch: false,
  text: null,
  filterParams: null,
  headhunterJobsFilters: null,
  headhunterJobs: false,
};

const QUOTES_REGEXP = /["|']/g;
const WORDS_REGEXP = /(?:'([^']+)'|"([^"]+)"|([^()\s]+))/g;
const BETWEEN_QUOTES_REGEXP = /^["'].*["']$/;

const DIACRITIC_REGEXP = /\p{Diacritic}/gu;
const REGEX = /(<<|>>)/g;

function keywords(words, mainSearch) {
  if (mainSearch) {
    const replaceQuotes = words.replace(QUOTES_REGEXP, '');
    return words.match(BETWEEN_QUOTES_REGEXP) ? [replaceQuotes] : replaceQuotes.match(WORDS_REGEXP) || [''];
  }

  const wordsWithoutFields = Array.from(words.matchAll(WORDS_REGEXP))
    .flatMap(match => {
      const word = match[1] || match[2] || match[3];
      return word.includes(':') ? word.split(':')[1] : word;
    })
    .filter(word => !['or', 'and'].includes(word.toLowerCase()) && word.trim() !== '');

  return wordsWithoutFields || [''];
}

function HighlighterKeywords(props) {
  const {
    text, filterParams, mainSearch,
    headhunterJobs, headhunterJobsFilters,
  } = props;

  let words = '';

  if (filterParams && !headhunterJobs) {
    const params = splitParams(filterParams);
    const paramContent = mainSearch ? params.main_keywords : params.keywords;
    words = paramContent !== undefined ? decodeURIComponent(paramContent) : '';
  }

  if (headhunterJobsFilters && Object.keys(headhunterJobsFilters).length) {
    words = headhunterJobsFilters.title !== undefined ? decodeURIComponent(headhunterJobsFilters.title) : '';
  }

  const searchWords = keywords(words, mainSearch);
  const textToHighlight = (text !== undefined && text !== null) ? text.replace(REGEX, '') : text;

  function sanitize(rawText) {
    if (!rawText) return rawText;
    // Normalize and unaccent text to compare
    return rawText.normalize('NFD').replace(DIACRITIC_REGEXP, '');
  }

  const chunks = findAll({
    caseSensitive: false,
    autoEscape: true,
    sanitize,
    searchWords,
    textToHighlight,
  });

  const formattedHTML = chunks.map((chunk) => {
    const { end, highlight, start } = chunk;
    const t = textToHighlight.substr(start, end - start);
    if (highlight) {
      return `<mark class="textHighlight">${t}</mark>`;
    }
    return t;
  }).join('');

  return (
    parser(formattedHTML)
  );
}

const mapStateToProps = (store) => ({
  filterParams: store.candidates.candidatesFilterParams,
  headhunterJobsFilters: store.headhunterJobs.headhunterJobsFilters,
});

HighlighterKeywords.propTypes = propTypes;
HighlighterKeywords.defaultProps = defaultProps;

export default connect(mapStateToProps, null)(HighlighterKeywords);
