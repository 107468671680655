import React from 'react';
import PropTypes from 'prop-types';

import background from 'images/cabecera_default.png';

const propTypes = {
  headerImagePreview: PropTypes.string,
  headerImageMobilePreview: PropTypes.string,
  job: PropTypes.shape({
    header_image: PropTypes.shape({
      url: PropTypes.string,
    }),
    header_image_mobile: PropTypes.shape({
      url: PropTypes.string,
    }),
    site: PropTypes.shape({
      header_image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    site_logo: PropTypes.string,
  }).isRequired,
  anonymousJob: PropTypes.bool.isRequired,
};

const defaultProps = {
  headerImagePreview: null,
  headerImageMobilePreview: null,
};

class HeadhunterJobInfo extends React.Component {
  constructor(props) {
    super(props);

    this.headerImage = this.headerImage.bind(this);
  }

  headerImage() {
    if (!this.props.anonymousJob) {
      if (this.props.headerImagePreview) {
        return { desktop: this.props.headerImagePreview, mobile: this.props.headerImageMobilePreview };
      } else if (this.props.job.header_image) {
        return { desktop: this.props.job.header_image.url, mobile: this.props.job.header_image_mobile?.url };
      } else if (this.props.job.site && this.props.job.site.header_image) {
        return { desktop: this.props.job.site.header_image.url, mobile: this.props.job.header_image_mobile?.url };
      }
    }
    return background;
  }

  render() {
    const { job: { site_logo: siteLogo } } = this.props;
    const headerImage = this.headerImage();
    let desktop = null;
    let mobile = null;
    if (headerImage instanceof Object) {
      ({ desktop, mobile } = headerImage);
    } else {
      desktop = headerImage;
    }

    return (
      <div className="main-external-header">
        {siteLogo &&
          <div className="wrapper-logo">
            <img src={siteLogo} />
          </div>
        }
        <picture>
          { mobile && <source srcSet={mobile} media="(max-width: 767px)" /> }
          <img src={desktop} />
        </picture>
      </div>
    );
  }
}

HeadhunterJobInfo.propTypes = propTypes;
HeadhunterJobInfo.defaultProps = defaultProps;

export default HeadhunterJobInfo;
