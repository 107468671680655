import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { firewallUnsupportedCharacters } from 'utils/form/validations';

const propTypes = {
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  renderField: PropTypes.func.isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
};

const defaultProps = {
  systemFieldsForm: [],
};

function JobPreference(props) {
  const {
    fieldLabel, fieldValidation, renderField,
    systemFieldsForm: {
      job_preference_desired_salary: desiredSalaryField,
      job_preference_min_salary: minSalaryField,
      job_preference_preferred_job: preferredJobField,
    },
    intl: { messages },
  } = props;

  if (!desiredSalaryField && !minSalaryField && !preferredJobField) return false;

  return (
    <div className="row form-group">
      {
        desiredSalaryField &&
          <div className="col-3 mobile-margin-bottom">
            {
              renderField(
                fieldLabel(desiredSalaryField, messages['jobs.desired_salary']),
                'desired_salary',
                fieldValidation(desiredSalaryField),
                messages['jobs.desired_salary'],
                'user.user_representations_attributes[0].job_preference_attributes.desired_salary',
              )
            }
          </div>
      }
      {
        minSalaryField &&
          <div className="col-3 mobile-margin-bottom">
            {
              renderField(
                fieldLabel(minSalaryField, messages['jobs.minimum_salary']),
                'min_salary_accepted',
                fieldValidation(minSalaryField),
                messages['jobs.minimum_salary'],
                'user.user_representations_attributes[0].job_preference_attributes.min_salary_accepted',
              )
            }
          </div>
      }
      {
        preferredJobField &&
          <div className="col-6">
            {
              renderField(
                fieldLabel(preferredJobField, messages['jobs.preferred_job']),
                'preferred_job',
                fieldValidation(preferredJobField, firewallUnsupportedCharacters),
                messages['jobs.preferred_job'],
                'user.user_representations_attributes[0].job_preference_attributes.preferred_job',
              )
            }
          </div>
      }
    </div>
  );
}

JobPreference.propTypes = propTypes;
JobPreference.defaultProps = defaultProps;

export default injectIntl(JobPreference);
